<template>
  <!--MODAL DELETE DAY-->
  <b-modal
    id="delete-period-modal"
    :title="$t('broadcast.deletePeriod')"
    :ok-disabled="$v.$invalid"
    :ok-title="$t('broadcast.delete')"
    :cancel-title="$t('table.cancel')"
    :busy="isModalBusy"
    @ok.prevent="showModalDeletePeriodConfirm"
    @show="clearData()"
  >
    <b-form-checkbox v-model="isFreeMode" name="check-delete-range-free-mode" switch :disabled="isModalBusy">
      {{ $t('broadcast.customPeriod') }}
    </b-form-checkbox>
    <div class="gap-2 grid-col-2">
      <b-form-group :label="$t('channelModal.from')" label-for="delete-period-datepicker-buttons-from">
        <datepicker-wrapper
          id="delete-period-datepicker-buttons-from"
          v-model="deletePeriod.dateFrom"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          :disabled="isModalBusy"
          required
        />
      </b-form-group>
      <b-form-group :label="$t('channelModal.to')" label-for="delete-period-datepicker-buttons-from-end">
        <datepicker-wrapper
          id="delete-period-datepicker-buttons-from-end"
          v-model="deletePeriod.dateFromEnd"
          :min="minDateFromEnd"
          :initial-date="deletePeriod.dateFrom"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          :disabled="!isFreeMode || isModalBusy"
          required
        />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import convertDate from '@/filters/convertDate';

function isDateFromEndBiggerFrom() {
  return new Date(this.deletePeriod.dateFromEnd) > new Date(this.deletePeriod.dateFrom);
}

export default {
  components: { DatepickerWrapper },
  filters: { convertDate },
  props: {
    channel: {
      required: true,
      type: Object,
    },
  },
  // defineEmits<{
  //   (e: 'periodDeleted'): void,
  // }>()
  data() {
    return {
      deletePeriod: {
        dateFrom: '',
        dateFromEnd: '',
      },
      isModalBusy: false,
      isFreeMode: false,
    };
  },
  validations: {
    deletePeriod: {
      dateFrom: { required },
      dateFromEnd: { required, isDateFromEndBiggerFrom },
    },
  },
  computed: {
    minDateFromEnd() {
      if (!this.deletePeriod.dateFrom) return undefined;
      const dateFrom = new Date(this.deletePeriod.dateFrom);
      dateFrom.setDate(dateFrom.getDate() + 1);
      return dateFrom;
    },
  },
  watch: {
    'deletePeriod.dateFrom'(newValue) {
      if (!newValue || this.isFreeMode) return;
      this.deletePeriod.dateFromEnd = this.setEndOfPeriod(newValue);
    },
    isFreeMode(newValue) {
      if (!newValue && this.deletePeriod.dateFrom) {
        this.deletePeriod.dateFromEnd = this.setEndOfPeriod(this.deletePeriod.dateFrom);
      }
    },
  },
  methods: {
    clearData() {
      this.deletePeriod.dateFrom = '';
      this.deletePeriod.dateFromEnd = '';
      this.isFreeMode = '';
    },

    setEndOfPeriod(dateStart) {
      if (!dateStart || this.isFreeMode) return;
      const to = new Date(dateStart);
      return new Date(to.getFullYear(), to.getMonth(), to.getDate() + 7).toISOString().slice(0, 10);
    },

    async showModalDeletePeriodConfirm() {
      const body = this.$createElement('p', {
        domProps: {
          innerHTML: `${this.$i18n.t('broadcast.confirmDeletePeriod')} <span class="text-danger">${convertDate(this.deletePeriod.dateFrom)} — ${convertDate(
            this.deletePeriod.dateFromEnd
          )}</span> ?`,
        },
      });
      const modalResult = await this.$bvModal
        .msgBoxConfirm(body, {
          title: this.$i18n.t('broadcast.deletePeriod'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'delete-period-confirm-modal',
        })
        .catch((err) => {});
      if (!modalResult) return;
      this.postDeletePeriod();
    },

    async postDeletePeriod() {
      this.isModalBusy = true;
      await this.$store.dispatch('POST_BROADCAST_DELETE_WEEK', {
        data: {
          channel: this.channel.id,
        },
        date: {
          date_from: this.deletePeriod.dateFrom,
          date_to: this.deletePeriod.dateFromEnd,
        },
        handler: (res) => {
          this.$notify({
            type: 'success',
            duration: 2000,
            title: this.$i18n.t('alert.deletedPeriod'),
            text: `<p class="mb-0 font-weight-bold">${this.$i18n.t('alert.deleted')}: </p>
                  ${this.$i18n.t('alert.programReleasesGC')}: ${res.data.result?.program_releases}<br/>
                  ${this.$i18n.t('alert.blocksGC')}: ${res.data.result?.blocks}`,
          });
          this.$bvModal.hide('delete-period-modal');
          this.$emit('periodDeleted');
          this.clearData();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },
  },
};
</script>
